import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { Image, Video, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`Embrace the Chill: Winter Trail Riding`}</h3>
    <br />
    <h4>{`Mountain biking on snow`}</h4>
    <p>
  We at Trailguide love any kind of bike riding (not really, actually we only
  like mountain biking) and we realised we were lacking a category for riding on
  snow.
  <br />
  <br />
  With an increasing number of enthusiasts and organisations shaping trails for snowy
  adventures, we decided to add the category “Winter Trail Biking”.
  <br />
  <br />
      <h4>{`Shaped trails`}</h4>
      <p>{`Trails for winter biking can be shaped by machines, dedicated locals pulling
car tires, or even by hikers. Riding your mountain bike on snow is quite a
unique adventure that will expand and transform your mountain bike experience.`}</p>
    </p>
    <br />
    <Image src="/news/winter-morten-trail.jpg" mdxType="Image">
  Well-prepped, hard-packed winter trail.
    </Image>
    <br />
    <br />
    <h4>{`Spiked Tires`}</h4>
    <p>
  Riding on snow will often require either spiked tires or a fatbike. We prefer
  slightly wider spiked tires on a normal mountain bike, maintaining playfulness
  when sliding through snowy corners while the spikes let you maintain
  confidence over icy sections.
    </p>
    <br />
    <h4>{`Snow conditions`}</h4>
    <p>
  Keep in mind that snow conditions change rapidly and sometimes the snow will
  for example be too soft to ride on. Keep an eye on snowfall and temperature to
  get an idea of the current conditions.
  <br />
  <br />
  You can check the most recent condition reports in the app or on the webpage. Better
  yet, contribute by sharing your own reports!
    </p>
    <br />
    <br />
    <div className="sm:flex sm:flex-wrap justify-around">
  <div className="w-full sm:w-1/3 sm:m-4 my-12">
    <IPhone className="sm:max-w-64" alt="Winter trail biking category" src={img("/news/iphone-winter-trail-biking.jpg")} text="Choose the Winter trail biking category on Trailguide to find prepped winter trails." mdxType="IPhone" />
  </div>
  <div className="w-full sm:w-1/3 sm:m-4 my-12">
    <IPhone className="sm:max-w-64" alt="Winter trail conditions" src={img("/news/iphone-winter-conditions.jpg")} text="Read or write condition reports through our app." mdxType="IPhone" />
  </div>
    </div>
    <br />
    <br />
    <p>
  In Drammen, we have a few winter trails and one of them is Linkløya,
  maintained by local riders and the owner of Link Sykkel himself.{" "}
  <Link href="https://trailguide.net/10288" mdxType="Link">You can find the trail here</Link>.
    </p>
    <br />
    <Video className="w-full mx-auto shadow-lg" src="n5i_tcCbjpE" mdxType="Video" />
    <p>{`A layer of snow will transform a technical trail into a smooth flow trail.`}</p>
    <br />
    <br />
    <p>
  Got winter trails in your area? We'd love to showcase them on Trailguide!
  Adding a trail is simple, and by sharing, you help grow our community of
  winter trail enthusiasts. Here's how to add a trail:
    </p>
    <br />
    <h4>{`New to Trailguide?`}</h4>
    <ul>
      <li parentName="ul">
        <Link to="/howto/add-trail" mdxType="Link">How to add a trail to Trailguide</Link>
      </li>
      <li parentName="ul">
        <Link to="/howto/install" mdxType="Link">How to install Trailguide as an app</Link>
      </li>
      <li parentName="ul">
        <Link to="/howto/navigation" mdxType="Link">How to use Navigation Mode</Link>
      </li>
      <li parentName="ul">
        <Link to="/howto/garmin-import" mdxType="Link">How to add a trail to your GPS device</Link>
      </li>
    </ul>
    <p>
  <br />
  <br />
  Regards,
  <br />
  Morten Trædal
  <br />
  Trailguide Influencer in Chief
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      